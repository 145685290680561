export default {
  proxy_path: "https://xlrk244plc.execute-api.us-east-1.amazonaws.com/api",
  proxy_path_geojson_api: "https://r0kj8ass3h.execute-api.us-east-1.amazonaws.com/api",
  google_api_key: "AIzaSyDVZjTV9W6b88zZ1cSax2y9SVBUckHtjCo",
  main_company_term_id: "191",
  crm_base_url: "testing.riotinto.forwoodsafety.com",
  custom_tile_url: "https://mapoverlays.geoeditor.testing.riotinto.forwoodsafety.com",
  MAP_REPORT_ACCESS_ROLE: "MapReportAccess",
  forwoodId: {
    URL: "https://id.testing.riotinto.forwoodsafety.com",
    APP_CLIENT_ID: "76ekq01k5ilq6mebelgl8ln7vp",
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://yimqnfn96d.execute-api.us-east-1.amazonaws.com/prod",
  },
  reactApp: {
    HOSTNAME: "https://mapreport.testing.riotinto.forwoodsafety.com",
  },
  get forwoodIdAuthUrl() {
    return `${
      this.forwoodId.URL
    }/authorize?response_type=token&scope=openid&client_id=${
      this.forwoodId.APP_CLIENT_ID
    }&redirect_uri=${this.reactApp.HOSTNAME}&state=`;
  },
};
